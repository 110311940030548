<template>
  <span>
    <tooltip :currentIndex="currentIndex" :message="message" messageType="data_format_error" :field="field" :field1="field1"></tooltip>
    <tooltip :currentIndex="currentIndex" :message="message" messageType="missing" :field="field" :field1="field1"></tooltip>
    <tooltip :currentIndex="currentIndex" :message="message" messageType="length_check_error" :field="field" :field1="field1"></tooltip>
  </span>
</template>
<script>
import tooltip from './tooltip.vue'
export default {
  name: 'tooltips',
  props: {
    message: {
      type: Object,
      default: () => {}
    },
    field: {
      type: String,
      default: ''
    },
    field1: {
      type: String,
      default: ''
    },
    currentIndex: {
      type: Number,
      default: -1
    },
    resetKey: {
      type: Boolean
    }
  },
  components: {
    tooltip
  },
  data () {
    return {
      itemKey: ''
    }
  }
}
</script>
