<template>
  <div class="wrap">
    <div class="wrap-header">
      <el-breadcrumb>
        <el-breadcrumb-item class="font-breadcrumb">
          <a>EDI</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item
          class="font-breadcrumb"
          :to="{ path: '/edi/invices' }">
          <a>Invoice</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item class="font-breadcrumb">
          <a @click="backPortal">Portal</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="AdvancedQuery">
          Advanced Query Result
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="wrap-header-btn">
        <el-button
          type="primary"
          plain
          size="small"
          class="public-fonts"
          @click="openDrawer('Advanced')"
        >
          Advanced Query
        </el-button>
      </div>
    </div>
    <div class="wrap-content">
      <el-row :gutter="20">
        <el-col v-show="!AdvancedQuery" :span="6">
          <span> Customer: </span>
          <el-select
            multiple
            @change="reSetPagination"
            placeholder=""
            v-model="customerValue">
            <el-option
              v-for="item in customerOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col v-show="!AdvancedQuery" :span="6">
          <span> Period: </span>
          <el-select
            @change="reSetPagination"
            v-model="periodValue">
            <el-option
              v-for="item in periodOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col v-show="!AdvancedQuery" :span="6">
          <span> Show: </span>
          <el-select @change="reSetPagination" v-model="showValue">
            <el-option
              v-for="item in showOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="AdvancedQuery ? 24 : 6" class="batch-btn">
          <el-button
            type="primary"
            plain
            size="small"
            class="public-fonts"
            @click="openDrawer('reGenerate')"
          >
            Re-generate per Batch
          </el-button>
        </el-col>
      </el-row>
      <el-row
        v-if="tableData.length"
        class="from-to"
        :gutter="20">
        <el-col>
          <span class="from">From&nbsp;</span>
          {{ fromDate }}
          <span style="font-weight: bold">&nbsp;To&nbsp;</span>
          {{ toDate }}
        </el-col>
      </el-row>
      <el-table
        empty-text="No available invoices."
        :data="tableData"
        @row-dblclick="rowDblclick"
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          label-class-name="title"
          prop="batch_no"
          label="Batch#"
        >
        </el-table-column>
        <el-table-column
          label-class-name="title"
          width="100"
          prop="customer"
          label="Customer"
        >
        </el-table-column>
        <el-table-column
          label-class-name="title"
          prop="invoice_no"
          label="Invoice#"
        >
        </el-table-column>
        <el-table-column label-class-name="title" prop="awb_no" label="AWB#/BL#">
        </el-table-column>
        <el-table-column
          label-class-name="title"
          prop="customer_code"
          label="Debtor"
        >
        </el-table-column>
        <el-table-column
          prop="loc_transaction_date"
          label-class-name="title"
          width="160"
          label="Transaction Date"
        >
        </el-table-column>
        <el-table-column
          label-class-name="title"
          width="90"
          prop="amount"
          label="Amount">
        </el-table-column>
        <el-table-column
          label-class-name="title"
          width="150"
          label="Generate Date"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.loc_gen_time | dateSwitch }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label-class-name="title"
          width="160"
          label="Preview Date/By"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.loc_preview_time }}</span>
            <span v-if="scope.row.review_by && scope.row.loc_preview_time">/</span>
            <span>{{ scope.row.review_by }}</span>
          </template>
        </el-table-column>
        <el-table-column label-class-name="title" width="150" label="Sent Date">
          <template slot-scope="scope">
            <span>{{ scope.row.loc_send_time | dateSwitch }}</span>
          </template>
        </el-table-column>
        <el-table-column label-class-name="title" prop="status" label="Status">
        </el-table-column>
        <el-table-column label-class-name="title" width="150" label="Action">
          <template slot-scope="scope">
            <el-button
              @click.native.stop="clickAction(scope.$index, scope.row)"
              class="action-btn"
              :class="{
                'baby-blue-btn': actionMap[scope.row.status] === 'Re-generate',
                checked: scope.row.active,
              }"
              size="medium"
              :type="actionMap[scope.row.status] !== 'Re-send' ? 'success' : ''"
            >
              <i v-show="scope.row.active" class="el-icon-check"></i>
              {{ actionMap[scope.row.status] }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-button
          v-show="this.tableData.length"
          type="primary"
          size="small"
          :disabled="validParams"
          @click="saveConfirmDialog = true"
        >
          Save
        </el-button>
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40]"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          :total="pagination.totalNum"
          layout="total, prev, slot, next, sizes, jumper, ->"
        >
          <span class="on-pager">{{ pagination.currentPage }}</span>
        </el-pagination>
      </div>
    </div>
    <el-dialog
      class="detail-dialog"
      :visible.sync="dialogVisible" width="60%">
      <div class="dialog-title" slot="title">
        <div>
          <span class="field-bolb">Invoice#</span>
          <span>{{ invoiceDetail.invoiceNo }}</span>
        </div>
        <div>
          <span class="field-bolb">Validation Result</span>
          <span :class="invoiceDetail.validationResult">
            {{ invoiceDetail.validationResult === "F" ? "Not pass" : "Pass" }}
          </span>
        </div>
      </div>
      <invoice-detail
        :key="detailNum"
        :validationMessage="validationMessage"
        :invoiceDetail="invoiceDetail"
        :serviceType="serviceType"
      >
      </invoice-detail>
    </el-dialog>
    <drawerRight
      :config="
        drawerDisplayType === 'Advanced'
          ? advancedQueryConfig
          : reGenerateConfig
      "
      :drawer.sync="drawer"
      @onConfirm="drawerOnConfirm"
      @onClose="resetForm('advanced')"
      @onCancel="drawerOnCancel"
    >
      <el-form
        v-show="drawerDisplayType === 'Advanced'"
        label-position="top"
        label-width="80px"
      >
        <el-form-item label="Customer">
          <el-select
            multiple
            filterable
            remote
            :loading="loading"
            placeholder=""
            :remote-method="remoteMethod"
            v-model="AdvancedCustomer">
            <el-option
              v-for="item in queryCustomerOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="AWB# / BL#">
          <el-input v-model="advancedQueryParams.awb" clearable></el-input>
        </el-form-item>
        <el-form-item label="MAWB# / MBL#">
          <el-input v-model="advancedQueryParams.mawb" clearable></el-input>
        </el-form-item>
        <el-form-item label="Invoice#">
          <el-input v-model="advancedQueryParams.invoice" clearable></el-input>
        </el-form-item>
        <el-form-item label="Batch#">
          <el-input v-model="advancedQueryParams.batch" clearable></el-input>
        </el-form-item>
        <el-form-item label="Transaction Date">
          <el-date-picker
            v-model="DateTransaction"
            type="daterange"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            start-placeholder="From Date"
            end-placeholder="To Date"
            range-separator="-"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Generate Date">
          <el-date-picker
            v-model="DateGenerate"
            type="daterange"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="From Date"
            end-placeholder="To Date"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Preview Date">
          <el-date-picker
            v-model="DatePreview"
            type="daterange"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="From Date"
            end-placeholder="To Date"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Sent Date">
          <el-date-picker
            v-model="DateSent"
            type="daterange"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="From Date"
            end-placeholder="To Date"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Status">
          <el-select placeholder="" clearable v-model="advancedQueryParams.status">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form
        v-show="drawerDisplayType !== 'Advanced'"
        :rules="rules"
        label-position="top"
        label-width="80px"
        ref="reGennerateRef"
        :model="reGennerate"
      >
        <el-form-item label="Customer" prop="customer">
          <el-select
            filterable
            remote
            placeholder=""
            clearable
            :remote-method="remoteMethod"
            v-model="reGennerate.customer"
            @change="queryBatch"
            :loading="loading"
          >
            <el-option
              v-for="item in gennerateCustomerOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Batch#" prop="batch">
          <el-select
            placeholder=""
            v-model="reGennerate.batch">
            <el-option
              v-for="item in gennerateBatchOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Business Service" prop="type">
          <el-select clearable v-model="reGennerate.type">
            <el-option
              v-for="item in businessServiceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </drawerRight>
    <el-dialog
      class="message-dialog"
      :visible.sync="saveConfirmDialog"
      top="30vh"
      width="400px"
    >
      <span class="save-confrim">
        Your actions for each invoice will be saved. Please double confirm if
        proceed.
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="saveConfirmDialog = false"
          >Cancel</el-button
        >
        <el-button type="primary" size="small" @click="saveInvoice"
          >OK</el-button
        >
      </span>
    </el-dialog>
    <ReGenerateConfirm
      :reGennerate="reGennerateConfig"
      :visible.sync="comfirmRegenrateDialog"
      @onComfirm="submitPerRegenerate"
    >
    </ReGenerateConfirm>
    <ReGenerateFailed
      :visible.sync="failRegenrateDialog"
      :reGennerate="reGennerateConfig"
      :errorMessage="errorMessage"
    >
    </ReGenerateFailed>
    <ReGenerateSuccess
      :visible.sync="successRegenrateDialog"
      :messageInvoice="messageInvoice"
      :reGennerate="reGennerateConfig"
      @checkInvoice="checkInvoice"
    >
    </ReGenerateSuccess>
    <save-message
      :responseData="saveData"
      :resultTitle="saveMessageTitle"
      :visible.sync="saveMessage">
    </save-message>
  </div>
</template>
<script>
import drawerRight from '../../components/drawer-right.vue'
import ReGenerateSuccess from '../../components/re-generate-success.vue'
import ReGenerateConfirm from '../../components/re-generate-confirm.vue'
import ReGenerateFailed from '../../components/re-generate-failed.vue'
import invoiceDetail from '../../components/invoice-detail.vue'
import saveMessage from '../../components/save-message.vue'
import * as api from '@/api/edi/portal'
import { getOfficeCode, getName } from '@/utils/auth'
let self = null
export default {
  components: {
    drawerRight,
    invoiceDetail,
    ReGenerateSuccess,
    ReGenerateFailed,
    ReGenerateConfirm,
    saveMessage
  },
  data () {
    return {
      customerOptions: [{
        value: 'ALLEGRO',
        label: 'ALLEGRO'
      },
      {
        value: 'All',
        label: 'ALL'
      }],
      queryCustomerOptions: [],
      periodOptions: [
        {
          value: 'Current Batch',
          label: 'Current Batch'
        },
        {
          value: 'Last Batch',
          label: 'Last Batch'
        },
        {
          value: 'Last Two Batches',
          label: 'Last Two Batches'
        },
        {
          value: 'Past 6 months',
          label: 'Past 6 months'
        }
      ],
      showOptions: [
        {
          value: 'Invoices pending for preview',
          label: 'Invoices pending for preview'
        },
        {
          value: 'Invoices done preview but not send',
          label: 'Invoices done preview but not send'
        },
        {
          value: 'Invoices already been sent',
          label: 'Invoices already been sent'
        },
        {
          value: 'Validation failed invoices',
          label: 'Validation failed invoices'
        },
        {
          value: 'Rejected invoices',
          label: 'Rejected invoices'
        },
        {
          value: 'All invoices',
          label: 'All invoices'
        }
      ],
      statusOptions: [
        {
          value: 'Hold',
          label: 'Hold'
        },
        {
          value: 'Error',
          label: 'Error'
        },
        {
          value: 'OK',
          label: 'OK'
        },
        {
          value: 'Sent',
          label: 'Sent'
        },
        {
          value: 'Generate failed',
          label: 'Generate failed'
        },
        {
          value: 'Accepted',
          label: 'Accepted'
        },
        {
          value: 'Rejected',
          label: 'Rejected'
        },
        {
          value: 'Re-generate failed',
          label: 'Re-generate failed'
        },
        {
          value: 'Sent failed',
          label: 'Sent failed'
        }
      ],
      value: '',
      tableData: [],
      errorTabelData: [],
      fromDate: '',
      toDate: '',
      currentPage: 1,
      dialogVisible: false,
      saveConfirmDialog: false,
      invoiceDetail: {},
      reGenerateConfig: {
        title: 'Re-generate per Batch Criteria',
        cancelText: 'Re-set',
        confirmText: 'Re-generate'
      },
      advancedQueryConfig: {
        title: 'Query Criteria',
        cancelText: 'Re-set',
        confirmText: 'Query'
      },
      drawer: false,
      reGennerateQueryParams: {},
      reGennerateConfig: {},
      reGennerate: {
        customer: '',
        batch: '',
        type: 'AIR'
      },
      messageInvoice: {
        totalInvoice: '',
        correctInvoice: '',
        failedInvoice: 0
      },
      errorMessage: '',
      drawerDisplayType: 'Advanced', // Advanced
      comfirmRegenrateDialog: false,
      failRegenrateDialog: false,
      successRegenrateDialog: false,
      AdvancedQuery: false, // display Advance Query Result page
      showTip: true,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalNum: 0
      },
      customerValue: ['ALLEGRO'],
      periodValue: 'Current Batch',
      showValue: 'Invoices pending for preview',
      actionMap: {
        Hold: 'OK',
        Error: 'Re-generate',
        OK: 'Re-generate',
        Sent: 'Re-send',
        Failed: 'Re-send',
        Accepted: 'Re-send',
        Rejected: 'Re-generate',
        'Re-generate failed': 'Re-generate',
        'Generate failed': 'Re-generate',
        'Sent failed': 'Re-send'
      },
      AdvancedCustomer: [],
      DateTransaction: [],
      DateGenerate: [],
      DatePreview: [],
      DateSent: [],
      advancedQueryParams: {
        customer: '',
        awb: '',
        mawb: '',
        invoice: '',
        batch: '',
        status: '',
        fromeDateTransaction: '',
        toDateTransaction: '',
        fromeDateGenerate: '',
        toDateGenerate: '',
        fromeDatePreview: '',
        toDatePreview: '',
        fromeDateSent: '',
        toDateSent: ''
      },
      cacheAdvancedQueryParams: {},
      saveParams: {},
      regenerateSaveParams: [],
      resendSaveParams: [],
      saveperParams: {},
      loading: false,
      gennerateCustomerOption: [],
      gennerateBatchOption: [],
      businessServiceOptions: [
        {
          label: 'AIR',
          value: 'AIR'
        },
        {
          label: 'SEA',
          value: 'SEA'
        },
        {
          label: 'BOTH',
          value: 'BOTH'
        }
      ],
      rules: {
        batch: [
          { required: true, message: 'Choose...', trigger: 'change' }
        ]
      },
      serviceType: '',
      validationMessage: {
        missing: [],
        data_format_error: [],
        length_check_error: []
      },
      detailNum: 0,
      saveMessage: false,
      saveData: [],
      saveMessageTitle: [],
      batchRegenerateParam: {},
      timer: null
    }
  },
  watch: {
    // 关闭刷新tip组件
    dialogVisible (newVal) {
      if (!newVal) {
        setTimeout(() => {
          ++this.detailNum
        }, 500)
      }
    }
  },
  created () {
    this.getProtalList()
    self = this
  },
  filters: {
    dateSwitch: function (dateTime) {
      if (!dateTime) return ''
      return self.$moment(dateTime).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  computed: {
    // 任何一个参数有值就返回 false
    validParams () {
      return !Object.keys(this.saveParams).length && !this.regenerateSaveParams.length && !this.resendSaveParams.length
    }
  },
  methods: {
    tableRowClassName ({ row }) {
      let className = row.status
      if (row.status === 'Re-generate failed' || row.status === 'Sent failed') {
        className = 'Failed'
      }
      return className
    },
    // 获取列表数据
    async getProtalList () {
      // 重置 save 参数
      this.saveParams = {}
      this.regenerateSaveParams = []
      this.resendSaveParams = []
      const customer = this.customerValue.includes('All') ? 'All' : this.customerValue.toString()
      const params = {
        customer,
        period: this.periodValue,
        show: this.showValue,
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
        name: getName()
      }
      const response = await api.getProtal(params, getOfficeCode())
      this.handleData(response)
    },
    // advancedQuery
    async advancedQueryFun () {
      // 重置 save 参数
      this.saveParams = {}
      this.tableData = []
      this.regenerateSaveParams = []
      this.resendSaveParams = []
      this.cacheAdvancedQueryParams.customer = this.AdvancedCustomer.toString() || 'All'
      this.cacheAdvancedQueryParams.fromeDateTransaction = this.DateTransaction?.length ? this.DateTransaction[0] : ''
      this.cacheAdvancedQueryParams.toDateTransaction = this.DateTransaction?.length ? this.DateTransaction[1] : ''
      this.cacheAdvancedQueryParams.fromeDateGenerate = this.DateGenerate?.length ? this.DateGenerate[0] : ''
      this.cacheAdvancedQueryParams.toDateGenerate = this.DateGenerate?.length ? this.DateGenerate[1] : ''
      this.cacheAdvancedQueryParams.fromeDatePreview = this.DatePreview?.length ? this.DatePreview[0] : ''
      this.cacheAdvancedQueryParams.toDatePreview = this.DatePreview?.length ? this.DatePreview[1] : ''
      this.cacheAdvancedQueryParams.fromeDateSent = this.DateSent?.length ? this.DateSent[0] : ''
      this.cacheAdvancedQueryParams.toDateSent = this.DateSent?.length ? this.DateSent[1] : ''
      this.cacheAdvancedQueryParams.name = getName()
      // bug #3260 如果都不输入默认获取前三个的月的值
      if (this.cacheAdvancedQueryParams.customer === 'All' &&
        !this.cacheAdvancedQueryParams.awb.length &&
        !this.cacheAdvancedQueryParams.mawb.length &&
        !this.cacheAdvancedQueryParams.invoice.length &&
        !this.cacheAdvancedQueryParams.batch.length &&
        !this.DateTransaction?.length &&
        !this.DateGenerate?.length &&
        !this.DatePreview?.length &&
        !this.DateSent?.length) {
        this.cacheAdvancedQueryParams.fromeDateTransaction = this.$moment(new Date()).subtract(3, 'months').format('YYYY-MM-DD')
        this.cacheAdvancedQueryParams.toDateTransaction = this.$moment(new Date()).format('YYYY-MM-DD')
      }
      this.cacheAdvancedQueryParams.awb = this.cacheAdvancedQueryParams.awb.toUpperCase()
      this.cacheAdvancedQueryParams.pageSize = this.pagination.pageSize
      this.cacheAdvancedQueryParams.currentPage = this.pagination.currentPage
      const response = await api.advancedQuery(this.cacheAdvancedQueryParams, getOfficeCode())
      this.handleData(response)
      this.resetForm('advanced')
    },
    // 处理搜索的记录
    handleData (response) {
      const { CODE, DATA } = response
      if (CODE === 200) {
        if (!DATA?.datas) return
        DATA.datas.forEach(item => {
          item.active = false
        })
        this.tableData = DATA.datas
        this.fromDate = DATA.fromDate
        this.toDate = DATA.toDate
        this.pagination.currentPage = DATA.currentPage
        this.pagination.pageSize = DATA.pageSize
        this.pagination.totalNum = DATA.totalNum
      }
    },
    // 关闭重置表单
    resetForm (type) {
      setTimeout(() => {
        this.$refs.reGennerateRef.resetFields()
        this.gennerateBatchOption = []
        this.AdvancedCustomer = []
        this.DateTransaction = []
        this.DateGenerate = []
        this.DatePreview = []
        this.DateSent = []
        this.advancedQueryParams = {
          customer: '',
          awb: '',
          mawb: '',
          invoice: '',
          batch: '',
          status: '',
          fromeDateTransaction: '',
          toDateTransaction: '',
          fromeDateGenerate: '',
          toDateGenerate: '',
          fromeDatePreview: '',
          toDatePreview: '',
          fromeDateSent: '',
          toDateSent: ''
        }
      }, 500)
    },
    // 点击 action 按钮
    clickAction (index, row) {
      row.active = !row.active
      this.$set(this.tableData, index, row)
      let regenerateIdIndex = this.regenerateSaveParams.indexOf(row.invoiceId)
      let resendIdIndex = this.resendSaveParams.indexOf(row.invoiceId)
      if (row.active) {
        const data = {
          name: row.review_by,
          action: this.actionMap[row.status],
          status: row.status,
          invoice_no: row.invoice_no
        }
        if (this.actionMap[row.status] === 'OK') {
          this.$set(this.saveParams, row.invoiceId, data)
        }
        if (this.actionMap[row.status] === 'Re-generate') {
          regenerateIdIndex === -1 && this.regenerateSaveParams.push(row.invoiceId)
        } else if (this.actionMap[row.status] === 'Re-send') {
          resendIdIndex === -1 && this.resendSaveParams.push(row.invoiceId)
        }
        return
      }
      if (this.actionMap[row.status] === 'OK') {
        for (const key in this.saveParams) {
          if (key === String(row.invoiceId)) {
            this.$delete(this.saveParams, key)
            break
          }
        }
      }
      if (this.actionMap[row.status] === 'Re-generate') {
        this.regenerateSaveParams.splice(regenerateIdIndex, 1)
      } else if (this.actionMap[row.status] === 'Re-send') {
        this.resendSaveParams.splice(resendIdIndex, 1)
      }
    },
    // 处理 save customer 的参数
    handlRegenerateParams (row, params) {
      // this.saveperParams 有无该属性 row.customer
      let obj = {
        invoice_no: row.invoice_no,
        customer: row.customer,
        type: row.service_type,
        batchNo: row.batch_no,
        office: row.ac_office_code
      }
      if (!Object.prototype.hasOwnProperty.call(params, row.customer)) {
        params[row.customer] = [{ [row.service_type]: obj }]
        return
      }
      const custObj = params[row.customer][0]
      if (!Object.prototype.hasOwnProperty.call(custObj, row.service_type)) {
        params[row.customer][0][row.service_type] = obj
        return
      }
      const typeObj = params[row.customer][0][row.service_type]
      typeObj.invoice_no += ',' + row.invoice_no
    },
    // 处理Resend参数
    handleResendParams () {
      const params = []
      const data = this.tableData.filter(item => this.resendSaveParams.includes(item.invoiceId))
      data.forEach(dataItem => {
        let flag = true
        let paramIndex = -1
        params.forEach((item, index) => {
          if (dataItem.batch_no === item.batchNo) {
            flag = false
            paramIndex = index
          }
        })
        if (flag) {
          params.push({
            batchNo: dataItem.batch_no,
            invoice_no: [dataItem.invoice_no]
          })
        } else {
          params[paramIndex].invoice_no.push(dataItem.invoice_no)
        }
      })
      return params
    },
    // 保存 invoice
    async saveInvoice () {
      if (this.validParams) return
      this.saveConfirmDialog = false
      let requests = []
      this.saveMessageTitle = []
      if (Object.keys(this.saveParams).length) {
        this.saveMessageTitle.push('Preview Result')
        const params = {
          invoice: [this.saveParams]
        }
        requests.push(api.saveInvoice(params, getName()))
      }
      if (this.regenerateSaveParams.length) {
        this.saveMessageTitle.push('Re-generate Result')
        const saveperParams = {}
        this.regenerateSaveParams.forEach(invoiceId => {
          this.tableData.forEach(item => {
            if (item.invoiceId === invoiceId) {
              this.handlRegenerateParams(item, saveperParams)
            }
          })
        })
        requests.push(api.regenerateInvoice(saveperParams))
      }
      if (this.resendSaveParams.length) {
        this.saveMessageTitle.push('Re-send Result')
        requests.push(api.reSendInvoice(this.handleResendParams(), getName()))
      }
      try {
        const res = await Promise.all(requests)
        this.saveData = res
        this.saveMessage = true
        this.regenerateSaveParams = []
        this.AdvancedQuery ? this.advancedQueryFun() : this.getProtalList()
      } catch (e) {
        this.$message.error(typeof e === 'string' ? e : 'Failed!')
      }
    },
    // 双击查看详情
    async rowDblclick (row) {
      this.serviceType = row.service_type
      const { DATA, CODE } = await api.queryInvoiceDetail(row.id)
      if (CODE === 200) {
        this.dialogVisible = true
        DATA.invoiceDetail.poNo = DATA.invoiceDetail.poNo?.filter(i => i) || []
        DATA.invoiceDetail.poNo = DATA.invoiceDetail.poNo.toString()
        if (!DATA.invoiceDetail.poNo) {
          DATA.validationMessage.missing.push('po_no_new')
        }
        this.invoiceDetail = DATA.invoiceDetail
        this.validationMessage = DATA.validationMessage
      }
    },
    // 打开抽屉
    openDrawer (type) {
      this.drawerDisplayType = type
      this.drawer = true
    },
    drawerOnCancel () {
      if (this.drawerDisplayType === 'reGenerate') {
        this.resetForm('reGennerateRef')
        return
      }
      this.resetForm('advanced')
    },
    async drawerOnConfirm () {
      if (await this.actionMessage()) {
        return
      }
      if (this.drawerDisplayType === 'reGenerate') {
        this.openReGenerateDialog()
        return
      }
      this.drawer = false
      this.AdvancedQuery = true
      this.cacheAdvancedQueryParams = JSON.parse(JSON.stringify(this.advancedQueryParams))
      this.advancedQueryFun()
    },
    // flag === false backPortal 不需要验证
    async reSetPagination (flag = true) {
      if (flag && await this.actionMessage()) {
        return
      }
      this.pagination = {
        currentPage: 1,
        pageSize: 10,
        totalNum: 0
      }
      this.tableData = []
      this.getProtalList()
    },
    async handleSizeChange (val) {
      if (await this.actionMessage()) {
        return
      }
      this.pagination.pageSize = val
      this.pagination.currentPage = 1
      this.AdvancedQuery ? this.advancedQueryFun() : this.getProtalList()
    },
    async handleCurrentChange (val) {
      if (await this.actionMessage()) {
        return
      }
      this.pagination.currentPage = val
      this.AdvancedQuery ? this.advancedQueryFun() : this.getProtalList()
    },
    // advanced Query页面点击面包屑导航返回普通搜索
    async backPortal () {
      if (!this.AdvancedQuery || await this.actionMessage()) return
      this.reSetPagination(false)
      this.AdvancedQuery = false
    },
    remoteMethod (query) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.queryCustomerSelectOptions(query)
        this.timer = null
      }, 900)
    },
    // customer 远程搜索
    async queryCustomerSelectOptions (query) {
      this.queryCustomerOptions = []
      this.gennerateCustomerOption = []
      this.loading = true
      const params = {
        customer: query
      }
      const { CODE, DATA } = await api.queryCustomer(params)
      if (CODE === 200 && DATA?.length) {
        const list = []
        DATA.forEach(item => {
          list.push({ value: item, label: item })
        })
        this.queryCustomerOptions = list
        this.gennerateCustomerOption = list
      }
      this.loading = false
    },
    // 搜索customer
    async queryBatch (query) {
      this.gennerateBatchOption = []
      this.reGennerate.batch = ''
      const params = {
        customer: query
      }
      const { CODE, DATA } = await api.queryBatch(params)
      if (CODE === 200 && DATA?.length) {
        DATA.forEach(item => {
          this.gennerateBatchOption.push({ value: item, label: item })
        })
      }
    },
    // 打开确认框
    async openReGenerateDialog () {
      if (!await this.$refs.reGennerateRef.validate()) return
      this.batchRegenerateParam = JSON.parse(JSON.stringify(this.reGennerate))
      this.reGennerateConfig = JSON.parse(JSON.stringify(this.reGennerate))
      this.comfirmRegenrateDialog = true
      this.drawer = false
    },
    // 提交批量生成
    async submitPerRegenerate () {
      if (this.batchRegenerateParam.type === 'BOTH') this.batchRegenerateParam.type = 'All'
      try {
        const { CODE, DATA, MESSAGE } = await api.perRegenerateInvoice(this.batchRegenerateParam)
        this.messageInvoice.totalInvoice = DATA?.length || 0
        this.errorTabelData = DATA?.filter(item => item.status === 'Error') || []
        const corrects = DATA?.filter(item => item.status === 'Hold') || []
        this.messageInvoice.failedInvoice = this.errorTabelData.length
        this.messageInvoice.correctInvoice = corrects.length
        if (CODE === 200) {
          this.AdvancedQuery ? this.advancedQueryFun() : this.getProtalList()
          this.successRegenrateDialog = true
        } else {
          this.failRegenrateDialog = true
          throw MESSAGE
        }
      } catch (e) {
        this.errorMessage = typeof e === 'string' ? e : 'Failed!'
        this.failRegenrateDialog = true
      }
      this.comfirmRegenrateDialog = false
    },
    // 重新生成invoice失败, 点击check invoice button检查错误数据
    checkInvoice () {
      this.errorTabelData.forEach(item => {
        item.active = false
      })
      this.tableData = this.errorTabelData
      this.successRegenrateDialog = false
    },
    /**
     * @description 如果当前页面点击action操作则需要提示
     * @return false: 忽略当前操作 & true: 终止跳转
     */
    async actionMessage () {
      let flag = false
      if (!this.validParams) {
        try {
          const confirm = await this.$confirm('Your actions for invoices will be lost.\nPlease double confirm if proceed.', '', {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            customClass: 'message-font'
          })
          if (confirm === 'confirm') flag = false
        } catch (e) {
          flag = true
        }
      }
      return flag
    }
  },
  // 页面跳转拦截
  async beforeRouteLeave (to, from, next) {
    if (!await this.actionMessage()) next()
  }
}
</script>
<style lang="scss" scoped>
$bg1: #1890ff;
.wrap {
  .wrap-header {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
  }
  .wrap-content {
    padding: 5px 24px 24px;
    min-height: 72vh;
    .content-table {
      width: 100%;
      border-radius: 8px;
    }
    ::v-deep {
      .title {
        color: #000;
        font-size: 16px;
      }
    }
    .el-row {
      margin: 20px 0;
      display: flex;
      align-items: center;
      .el-col {
        display: flex;
        align-items: center;
        span:nth-child(1) {
          margin-right: 10px;
        }
      }
    }
    .footer {
      .el-button {
        margin-right: 40px;
      }
      margin: 32px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
.el-table {
  ::v-deep {
    .Hold {
      background-color: rgb(255, 255, 204);
    }
    .Error {
      background-color: rgb(255, 204, 204);
    }
    .Rejected {
      background-color: rgb(255, 204, 204);
    }
    .Failed {
      background-color: rgb(255, 230, 204);
    }
    .title {
      color: #000;
      font-size: 16px;
    }
  }
  .action-btn {
    width: 100%;
  }
  .el-button--medium {
    padding: 10px 0;
  }
  .checked {
    opacity: 0.7;
  }
}
.el-table .success-row {
  background: #f0f9eb;
}
.dialog-title {
  div {
    span:nth-child(1) {
      margin-right: 10px;
    }
  }
  div:nth-child(1) {
    margin-bottom: 10px;
  }
  .F {
    color: red;
  }
  .S {
    color: green;
  }
}
.field-bolb {
  font-weight: bold;
  color: #000;
}
::v-deep {
  .el-select {
    width: 100%;
  }
  .el-dialog__header {
    border-bottom: 1px solid #ccc;
  }
  .el-dialog {
    max-height: 70%;
    overflow: auto;
  }
  .el-drawer {
    .el-drawer__body {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .drawer-container {
        height: 100%;
        overflow: auto;
        padding: 24px;
        padding-top: 0;
        .el-form-item {
          margin-bottom: 0;
        }
      }
      .el-drawer-footer {
        min-height: 75px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #ccc;
        padding: 0 24px;
      }
      .el-input__inner {
        width: 100%;
      }
    }
  }
}
.el-select-dropdown__item {
  text-transform: none !important;
}
.public-fonts {
  border-radius: 5px;
}
.from-to {
  padding-top: 10px;
  .from {
    margin-right: 0 !important;
  }
  span {
    font-weight: bold;
  }
}
.save-confrim {
  color: rgb(16, 134, 255);
  display: block;
  line-height: 25px;
  padding: 20px 10px;
  word-break: break-word;
}
.batch-btn {
  justify-content: flex-end;
  padding-right: 0 !important;
}
.detail-dialog {
  ::v-deep {
    .el-dialog {
      height: 100%;
      overflow: hidden;
      .el-dialog__body {
        height: 78%;
        overflow: auto;
      }
    }
  }
}
</style>
<style lang="scss">
.message-font .el-message-box__message {
  color: #1086ff;
  line-height: 25px;
  padding: 1px 10px;
  // word-break: break-word;
  font-size: 16px;
  white-space: pre-wrap;
}
</style>
