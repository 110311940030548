<template>
  <div>
    <el-dialog
      class="message-dialog"
      :visible.sync="childVisible"
      @close="close"
      top="30vh"
      width="400px">
      <div slot="title" class="red">Re-generate invoices failed!</div>
      <div>
        <div class="message-line">
          <span>Customer : </span>
          <span>{{reGennerate.customer}}</span>
        </div>
        <div class="message-line">
          <span>Batch# : </span>
          <span>{{reGennerate.batch}}</span>
        </div>
      </div>
      <div class="message red">
        Error Message:<br/>
        {{ errorMessage }}
      </div>
      <div>
        Please try it again later. If still failed, contact icil_sup@icil.net for further checking.
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="onConfirm">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    customer: {
      type: String,
      default: ''
    },
    Batch: {
      type: String,
      default: ''
    },
    reGennerate: {
      type: Object,
      default: () => {}
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  watch: {
    visible (newVal) {
      this.childVisible = newVal
    }
  },
  data () {
    return {
      childVisible: false
    }
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    onConfirm () {
      this.$emit('update:visible', false)
      this.$emit('onComfirm')
    }
  }
}
</script>
<style lang="scss" scoped>
.message-line {
  font-size: 16px;
  margin-bottom: 10px ;
}
.message{
  border-top: 1px solid #ccc;
  padding: 10px 0;
  color: rgb(42, 148, 255);
  line-height: 30px;
  word-break: break-word;
}
.red {
  color: red;
}
</style>
