<template>
  <div>
    <el-dialog
      class="message-dialog"
      :visible.sync="childVisible"
      @close="close"
      top="30vh"
      width="400px">
      <div>
      <div class="message-line">
        <span>Customer : </span>
        <span> {{ reGennerate.customer }} </span>
      </div>
      <div class="message-line">
        <span>Batch# : </span>
        <span>{{ reGennerate.batch }}</span>
      </div>
      </div>
      <div class="message">
        All NOT 'Accepted" invoices of the selected batch will be re-generated.
      </div>
      <div style="font-size: 15px;">
        Please double confirm if proceed.
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">Cancel</el-button>
        <el-button size="small" type="primary" @click="onConfirm">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    customer: {
      type: String,
      default: ''
    },
    Batch: {
      type: String,
      default: ''
    },
    reGennerate: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    visible (newVal) {
      this.childVisible = newVal
    }
  },
  data () {
    return {
      childVisible: false
    }
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    onConfirm () {
      this.$emit('onComfirm')
    }
  }
}
</script>
<style lang="scss" scoped>
.message-line {
  font-size: 16px;
  margin-bottom: 10px ;
  word-break: break-word;
}
.message{
  font-size: 15px;
  padding: 10px 0;
  color: rgb(42, 148, 255);
  border-top: 1px solid #ccc;
}
</style>
