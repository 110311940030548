<template>
  <div>
    <el-dialog
      class="message-dialog"
      :visible.sync="childVisible"
      @close="close"
      top="30vh"
      width="600px">
      <div class="error-message">
        <div v-for="(resItem, resIndex) in responseData" :key="resIndex">
          <div class="title">{{ resultTitle[resIndex] }}</div>
          <table>
            <template v-if="resItem.CODE === 200 && resItem.DATA && resItem.DATA.length">
              <tr v-for="(item, index) in resItem.DATA" :key="index">
                <td>{{ handleInvoice(item) }}</td>
                <td :class="{ 'error-color': item.validation_result !== 'S' }">{{ handleResult(item) }}</td>
              </tr>
            </template>
            <tr v-else-if="resItem.CODE === 200 && !resItem.DATA.length">
              <td>Success</td>
            </tr>
            <tr v-else>
              <td class="error-color">
                <span >Fail,</span>
                {{ resItem.MESSAGE }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="onConfirm">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'saveMessage',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    responseData: {
      type: Array,
      default: () => []
    },
    resultTitle: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    visible (newVal) {
      this.childVisible = newVal
    }
  },
  data () {
    return {
      childVisible: false
    }
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    onConfirm () {
      this.$emit('update:visible', false)
      this.$emit('onComfirm')
    },
    handleResult (row) {
      if (row.validation_result === 'F' || row.send_result === 'F') {
        return 'Fail, ' + (row.generate_error || row.send_msg)
      }
      const keys = Object.keys(row)
      if (keys.length === 1) {
        return 'Fail, ' + row[keys]
      }
      return 'Success'
    },
    handleInvoice (row) {
      if (row?.invoice_no) {
        return row.invoice_no
      }
      const keys = Object.keys(row)
      if (keys.length) {
        let invoices = ''
        keys[0].split(',').forEach(item => {
          invoices = invoices + item + '\n'
        })
        return invoices
      }
      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
.error-message {
  margin: 20px 0;
  max-height: 300px;
  overflow: auto;
  div {
    margin-bottom: 20px;
  }
  :last-child {
    margin-bottom: 0;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    th {
      font-weight: 500;
      border: 1px solid rgb(161, 161, 161);
    }
    tr {
      :first-child {
        width: 30%;
        white-space: pre-wrap;
      }
    }
    td {
      padding: 5px;
      border: 1px solid rgb(161, 161, 161);
    }
  }
  .error-color {
    color: red;
  }
}
</style>
