<template>
  <span
    v-if="handleMessage()"
    class="error-message">
    <span>{{ messageText[messageType] }}</span>
  </span>
</template>
<script>
export default {
  name: 'tooltip',
  props: {
    messageType: {
      type: String,
      default: ''
    },
    message: {
      type: Object,
      default: () => {}
    },
    field: {
      type: String,
      default: ''
    },
    field1: {
      type: String,
      default: ''
    },
    currentIndex: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      topHeight: '30',
      showTip: true,
      messageText: {
        data_format_error: 'Incorrect format',
        missing: 'Data missing',
        length_check_error: 'Data length too short/too long'
      },
      validationMessageMap: {
        voucherNo: 'voucher_no',
        transactionDate: 'loc_transaction_date',
        debtor: 'debtor',
        c: 'debtor_name_new',
        carrier: 'first_carrier',
        departAirport: 'depart_airport',
        destAirport: 'dest_airport',
        serviceLevel: '未定',
        vessel: 'vessel',
        voyage: 'first_flt_num',
        onboardDate: 'loc_on_board_date',
        placeReceipt: 'origin',
        placeLoading: 'dest',
        serviceType: 'service type',
        totalNoOfContainer: 'no_of_container',
        totalNoOfPkg: 'no_of_pkg',
        volume: 'volume',
        grossWeight: 'gross_weight',
        chargWeight: 'awb_chrg_qty',
        commodity: 'commodity_new',
        billedWeight: 'gross_weight',
        chargesInfo: 'invDistributionInfo',
        shipperName: 'shipper_name_new',
        shAddress1: 'sh_address1',
        cnosName: 'cons_name_new',
        cnAddress1: 'cn_address1_new',
        containerDetail: 'containerDetailList',
        poNo: 'po_no_new',
        pickupDate: 'Dept_Date_Action_Date_RCS',
        podDate: 'Dept_Date_Action_Date_DL1'
      },
      chargesInfoIndex: -1
    }
  },
  mounted () {
    // this.topHeight = this.$refs.tooltipRef?.offsetHeight + 5
  },
  methods: {
    handleMessage () {
      if (!this.message) return false
      if (this.field === 'chargesInfo') {
        let flag = false
        for (let i = 0; i < this.message.data_format_error.length; i++) {
          const item = this.message.data_format_error[i]
          let arr = item.split('/')
          if (arr.length === 3 &&
            arr[0] === this.field &&
            +arr[1] === this.currentIndex &&
            this.messageType === 'data_format_error' &&
            arr[2] === this.field1) {
            flag = true
          }
        }
        if (flag) return flag
        for (let i = 0; i < this.message.missing.length; i++) {
          const item = this.message.missing[i]
          let arr = item.split('/')
          if (arr.length === 3 &&
            arr[0] === this.field &&
            +arr[1] === this.currentIndex &&
            this.messageType === 'message' &&
            arr[2] === this.field1) {
            return true
          }
        }
        if (flag) return flag
        for (let i = 0; i < this.message.length_check_error.length; i++) {
          const item = this.message.length_check_error[i]
          let arr = item.split('/')
          if (arr.length === 3 &&
            arr[0] === this.field &&
            +arr[1] === this.currentIndex &&
            this.messageType === 'length_check_error' &&
            arr[2] === this.field1) {
            return true
          }
        }
        return flag
      }
      return this.message[this.messageType]?.includes(this.validationMessageMap[this.field])
    }
  }
}
</script>
<style lang="scss" scoped>
.tooltip {
  position: absolute;
  // top: -30px;
  color: red;
  padding: 5px;
  background-color: #fff ;
  border: 1px solid #000;
  border-radius: 4px;
  white-space: nowrap;
  font-weight: normal;
  z-index: 10;
  &:after {
    position: absolute;
    width: 6px;
    height: 6px;
    content: "";
    bottom: -4px;
    left: 10px;
    border: 1px solid #000;
    background-color: #fff;
    border-top-color: transparent;
    border-left-color: transparent;
    // border-bottom-color: red;
    transform: rotate(45deg);
  }
  .el-icon-circle-close {
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
    background: #fff;
  }
}
.error-message {
  position: absolute;
  font-size: 12px;
  color: red;
  bottom: -15px;
  width: 90%;
  left: 0;
  display: flex !important;
  justify-content: flex-end;
  span {
    white-space: nowrap;
    font-weight: 500;
  }
  &:before {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    border-top: 1px solid red;
  }
}
</style>
