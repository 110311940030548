<template>
  <div class="dialog-contanier">
    <div class="contanier-left">
      <div class="contanier-item">
        <div class="tips">
          <span class="field-bolb">Voucher# :&nbsp;</span>
          <span>{{ invoiceDetail.voucherNo }}</span>
          <tooltips :message="validationMessage" field="voucherNo"></tooltips>
        </div>
        <div class="tips">
          <span class="field-bolb">Transaction Date :&nbsp;</span>
          <span>{{ invoiceDetail.transactionDate | dateSwitch }}</span>
          <tooltips :message="validationMessage" field="transactionDate"></tooltips>
        </div>
      </div>
      <div class="contanier-item">
        <div class="title">Debtor Info</div>
        <div class="tips">
          {{ invoiceDetail.debtor }}
          <tooltips :message="validationMessage" field="debtor"></tooltips>
        </div>
        <div class="tips">
          {{ invoiceDetail.debtorName }}
          <tooltips :message="validationMessage" field="debtorName"></tooltips>
        </div>
        <div class="tips">
          {{ invoiceDetail.debtorAddress1 }}
          <tooltips :message="validationMessage" field="debtorAddress1"></tooltips>
        </div>
        <div class="tips">
          {{ invoiceDetail.debtorAddress2 }}
          <tooltips :message="validationMessage" field="debtorAddress2"></tooltips>
        </div>
      </div>
      <div class="contanier-item">
        <div class="title">Charges Info</div>
        <div class="charges-info"
          v-for="(item, index) in invoiceDetail.chargesInfo"
          :key="index">
          <div class="field-bolb tips">
            {{ item.particular }}
            <tooltips
              :currentIndex="index"
              :message="validationMessage"
              field1="particular_new"
              field="chargesInfo">
            </tooltips>
          </div>
          <div class="tips amount">
            {{ item['credit-debit'] }} {{ invoiceDetail.currency }}
            <tooltips
              :currentIndex="index"
              :message="validationMessage"
              field1="credit-debit"
              field="chargesInfo">
            </tooltips>
          </div>
        </div>
      </div>
      <div class="total-amt">
        <span class="field-bolb">Total Amt :&nbsp;</span>
        <span>{{ invoiceDetail.totalAmt }} {{ invoiceDetail.currency }}</span>
      </div>
    </div>
    <div class="contanier-right">
      <div class="contanier-item">
        <div>
          <span class="field-bolb">HAWB# / HBL# :&nbsp;</span>
          <span>{{ invoiceDetail.awbNo }}</span>
        </div>
        <div>
          <span class="field-bolb">MAWB# / MBL# :&nbsp;</span>
          <span>{{ invoiceDetail.mawbNo }}</span>
        </div>
      </div>
      <div class="contanier-item right-info">
        <div class="right-info-item">
          <div class="title">Basic Info</div>
          <template v-if="serviceType !== 'SEA'">
            <div class="tips">
              <span class="field-bolb">Carrier :&nbsp;</span>
              <span>{{ invoiceDetail.carrier }}</span>
              <tooltips :message="validationMessage" field="carrier"></tooltips>
            </div>
            <div class="tips">
              <span class="field-bolb">Depart Airport :&nbsp;</span>
              <span>{{ invoiceDetail.departAirport }}</span>
              <tooltips :message="validationMessage" field="departAirport"></tooltips>
            </div>
            <div class="tips">
              <span class="field-bolb">Dest. Airport :&nbsp;</span>
              <span>{{ invoiceDetail.destAirport }}</span>
              <tooltips :message="validationMessage" field="destAirport"></tooltips>
            </div>
            <div class="tips">
              <span class="field-bolb">Service Level :&nbsp;</span>
              <span>{{ invoiceDetail.serviceLevel }}</span>
              <tooltips :message="validationMessage" field="serviceLevel"></tooltips>
            </div>
          </template>
          <template v-else>
            <div class="tips">
              <span class="field-bolb">Vessel/Voyage :&nbsp;</span>
              <span>{{ invoiceDetail.vessel }}</span>
              <tooltips :message="validationMessage" field="vessel"></tooltips>
            </div>
            <div class="tips">
              <span class="field-bolb">Onboard Date :&nbsp;</span>
              <span>{{ invoiceDetail.onboardDate | dateSwitch }}</span>
              <tooltips :message="validationMessage" field="onboardDate"></tooltips>
            </div>
            <div class="tips">
              <span class="field-bolb">Place Receipt :&nbsp;</span>
              <span>{{ invoiceDetail.placeReceipt }}</span>
              <tooltips :message="validationMessage" field="placeReceipt"></tooltips>
            </div>
            <div class="tips">
              <span class="field-bolb">Place Loading :&nbsp;</span>
              <span>{{ invoiceDetail.placeLoading }}</span>
              <tooltips :message="validationMessage" field="placeLoading"></tooltips>
            </div>
            <div class="tips">
              <span class="field-bolb">Service Type :&nbsp;</span>
              <span>{{ invoiceDetail.serviceType }}</span>
              <tooltips :message="validationMessage" field="serviceType"></tooltips>
            </div>
            <div class="tips">
              <span class="field-bolb">Total No. of Container :&nbsp;</span>
              <span>{{ invoiceDetail.totalNoOfContainer }}</span>
              <tooltips :message="validationMessage" field="totalNoOfContainer"></tooltips>
            </div>
          </template>
        </div>
        <div class="right-info-item">
          <div class="title">Cargo Info</div>
          <div class="tips">
            <span class="field-bolb">Total No. of Pkg :&nbsp;</span>
            <span>{{ invoiceDetail.totalNoOfPkg }}</span>
            <span v-if="invoiceDetail.totalNoOfPkg">&nbsp;PCS</span>
            <tooltips :message="validationMessage" field="totalNoOfPkg"></tooltips>
          </div>
          <div class="tips">
            <span class="field-bolb">Volume :&nbsp;</span>
            <span>{{ invoiceDetail.volume }}</span>
            <span v-if="invoiceDetail.volume">&nbsp;CBM</span>
            <tooltips :message="validationMessage" field="volume"></tooltips>
          </div>
          <div class="tips">
            <span class="field-bolb">Gross Weight :&nbsp;</span>
            <span>{{ invoiceDetail.grossWeight }}</span>
            <span v-if="invoiceDetail.grossWeight">&nbsp;KG</span>
            <tooltips :message="validationMessage" field="grossWeight"></tooltips>
          </div>
          <div v-if="serviceType === 'SEA'" class="tips">
            <span class="field-bolb">Billed Weight :&nbsp;</span>
            <span>{{ invoiceDetail.billedWeight }}</span>
            <span v-if="invoiceDetail.billedWeight">&nbsp;KG</span>
            <tooltips :message="validationMessage" field="billedWeight"></tooltips>
          </div>
          <div v-else class="tips">
            <span class="field-bolb">Chrg.Weight :&nbsp;</span>
            <span>{{ invoiceDetail.chargWeight }}</span>
            <span v-if="invoiceDetail.chargWeight">&nbsp;KG</span>
            <tooltips :message="validationMessage" field="chargWeight"></tooltips>
          </div>
          <div class="tips">
            <span class="field-bolb">Commodity :&nbsp;</span>
            <span>{{ invoiceDetail.commodity }}</span>
            <tooltips :message="validationMessage" field="commodity"></tooltips>
          </div>
        </div>
      </div>
      <div class="contanier-item right-info">
        <div class="right-info-item">
          <div class="title">Shipper Info</div>
          <div class="tips">
            <span>{{ invoiceDetail.shipperName }}</span>
            <tooltips :message="validationMessage" field="shipperName"></tooltips>
          </div>
          <div class="tips">
            <span>{{ invoiceDetail.shAddress1 }}</span>
            <tooltips :message="validationMessage" field="shAddress1"></tooltips>
          </div>
          <div class="tips">
            <span>{{ invoiceDetail.shAddress2 }}</span>
            <tooltips :message="validationMessage" field="shAddress2"></tooltips>
          </div>
        </div>
        <div class="right-info-item">
          <div class="title">Consignee Info</div>
          <div class="tips">
            <span>
              {{ invoiceDetail.cnosName }}
            </span>
            <tooltips :message="validationMessage" field="cnosName"></tooltips>
          </div>
          <div class="tips">
            <span>
              {{ invoiceDetail.cnAddress1 }}
            </span>
            <tooltips :message="validationMessage" field="cnAddress1"></tooltips>
          </div>
          <div class="tips">
            <span>
              {{ invoiceDetail.cnAddress2 }}
            </span>
            <tooltips :message="validationMessage" field="cnAddress2"></tooltips>
          </div>
        </div>
      </div>
      <div class="contanier-item" v-if="serviceType === 'SEA' && invoiceDetail.containerDetail">
        <div class="info-item">
          <div class="title">Container Detail</div>
          <div class="title-row">
            <span class="field-bolb">Container Number</span>
            <span class="field-bolb">Container type</span>
            <span class="field-bolb">No. of Pkg</span>
          </div>
          <div
            class="title-row"
            v-for="(item, index) in invoiceDetail.containerDetail"
            :key="index">
            <span>{{ item.container_no }}</span>
            <span>{{ item.container_type }}</span>
            <span>{{ item.no_of_pkg }}</span>
          </div>
        </div>
      </div>
      <div class="contanier-item right-info">
        <div class="right-info-item">
          <div class="title">Other Info</div>
          <div class="tips">
            <span class="field-bolb">Incoterms :&nbsp;</span>
            <span>{{ invoiceDetail.incoterms }}</span>
            <tooltips :message="validationMessage" field="incoterms"></tooltips>
          </div>
          <div class="tips">
            <span class="field-bolb">PO# :&nbsp;</span>
            <span>{{ invoiceDetail.poNo }}</span>
            <tooltips :message="validationMessage" field="poNo"></tooltips>
          </div>
        </div>
        <div class="right-info-item">
          <div class="title hidden-placeholder">.</div>
          <div
            v-if="serviceType !== 'SEA'"
            class="tips">
            <span class="field-bolb">Pickup Date :&nbsp;</span>
            <span>{{ invoiceDetail.pickupDate | dateSwitch }}</span>
            <tooltips :message="validationMessage" field="pickupDate"></tooltips>
          </div>
          <div class="tips">
            <span class="field-bolb">POD Date :&nbsp;</span>
            <span>{{ invoiceDetail.podDate | dateSwitch }}</span>
            <tooltips :message="validationMessage" field="podDate"></tooltips>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tooltips from './tooltips.vue'
let self = null
export default {
  name: 'invoiceDetail',
  components: {
    tooltips
  },
  props: {
    validationMessage: {
      Type: Object,
      default: () => {}
    },
    invoiceDetail: {
      Type: Object,
      default: () => {}
    },
    serviceType: {
      Type: String,
      default: ''
    }
  },
  created () {
    self = this
  },
  filters: {
    dateSwitch: function (dateTime) {
      if (!dateTime) return ''
      return self.$moment(dateTime).format('YYYY-MM-DD')
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-contanier {
  display: flex;
  justify-content: space-between;
  .contanier-left {
    width: 35%;
    .charges-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        min-width: 50%;
      }
      .amount {
        text-align: right;
        ::v-deep .error-message {
          left: auto;
          right: 0;
        }
      }
    }
    .total-amt {
      text-align: right;
    }
  }
  .contanier-right {
    width: 63%;
    .right-info {
      display: flex;
      justify-content: space-between;
      .right-info-item {
        display: flex;
        flex-direction: column;
        width: 50%;
      }
    }
  }
  .contanier-item {
    border-bottom: 1px dashed #ccc;
    margin-bottom: 15px;
    .title {
      font-weight: bold;
      color: rgb(131, 169, 225);
    }
    .hidden-placeholder {
      opacity: 0;
    }
    div {
      margin-bottom: 10px;
    }
    .info-item {
      width: 100%;
      .title-row {
        display: flex;
        span {
          display: block;
          padding-left: 2px;
          width: 32%;
          max-width: 33%;
        }
      }
    }
  }
}
.field-bolb {
  font-weight: bold;
  color: #000;
}
.tips {
  position: relative;
  line-height: 17px;
  span {
    display: inline-block;
  }
}
.error-message {
  position: absolute;
  font-size: 12px;
  color: red;
  bottom: -15px;
  width: 90%;
  left: 0;
  display: flex !important;
  justify-content: flex-end;
  span {
    white-space: nowrap;
    font-weight: 500;
  }
  &:before {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    border-top: 1px solid red;
  }
}
</style>
