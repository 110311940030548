import request from '@/utils/request'
const PROTAL_URL = '/limosa-01/service/invoice/portal'
const ADVANCED_QUERY_URL = '/limosa-01/service/invoice/advancedQuery'
const SAVE_INVOICE_URL = '/limosa-01/service/save/invoice'
const QUERY_CUSTMOER_URL = '/limosa-01/service/qeuery/customer'
const QUERY_BATCH_URL = '/limosa-01/service/qeuery/batch'
const PER_REGENERATA_URL = '/limosa-01/service/perRegenerate/invoice'
const REGENERATA_URL = '/limosa-01/service/regenerate/invoice'
const QUERY_INVOICE_DETAIL_URL = '/limosa-01/service/invoice/queryInvoiceDetail/'
const RESEND_INVOICE_URL = '/limosa-01/service/invoice/reSendInvoice'

export const reSendInvoice = (data, userName) => {
  return new Promise((resolve, reject) => {
    request.post(RESEND_INVOICE_URL + '?userName=' + userName, data, { loading: true })
      .then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
  })
}

export const queryInvoiceDetail = (id) => {
  return new Promise((resolve, reject) => {
    request.get(QUERY_INVOICE_DETAIL_URL + id, { loading: true })
      .then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
  })
}

export const regenerateInvoice = (data) => {
  return new Promise((resolve, reject) => {
    request.post(REGENERATA_URL, data, { loading: true })
      .then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
  })
}

export const perRegenerateInvoice = (data) => {
  return new Promise((resolve, reject) => {
    request.post(PER_REGENERATA_URL, data, { loading: true })
      .then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
  })
}
export const queryBatch = (data) => {
  return new Promise((resolve, reject) => {
    request.post(QUERY_BATCH_URL, data, { loading: false })
      .then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
  })
}
export const queryCustomer = (data) => {
  return new Promise((resolve, reject) => {
    request.post(QUERY_CUSTMOER_URL, data, { loading: false })
      .then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
  })
}
export const saveInvoice = (data, userName) => {
  return new Promise((resolve, reject) => {
    request.post(SAVE_INVOICE_URL + '?userName=' + userName, data, { loading: true })
      .then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
  })
}

export const getProtal = (data, officeCode) => {
  return new Promise((resolve, reject) => {
    const url = PROTAL_URL + '?office=' + officeCode
    request.post(url, data, { loading: true })
      .then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
  })
}

export const advancedQuery = (data, officeCode) => {
  return new Promise((resolve, reject) => {
    const url = ADVANCED_QUERY_URL + '?office=' + officeCode
    request.post(url, data, { loading: true })
      .then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
  })
}
