<template>
  <div>
    <el-dialog
      class="message-dialog"
      :visible.sync="childVisible"
      @close="close"
      top="30vh"
      width="400px">
      <div slot="title" class="green">Re-generate invoices successfully !</div>
      <div class="message-basic">
        <div class="message-line">
          <span>Customer : </span>
          <span>{{reGennerate.customer}}</span>
        </div>
        <div class="message-line">
          <span>Batch# : </span>
          <span>{{reGennerate.batch}}</span>
        </div>
        <div class="message-line">
          <span>Total Invoices: </span>
          <span>{{ messageInvoice.totalInvoice }}</span>
        </div>
      </div>
      <div class="result">
        <div class="title message-line">Result</div>
        <div class="message-line">
          <span>Correct Invoices : </span>
          <span>{{ messageInvoice.correctInvoice }}</span>
        </div>
        <div class="message-line">
          <span class="red">Validation failed invoices : </span>
          <span class="red">{{ messageInvoice.failedInvoice }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="messageInvoice.failedInvoice"
          size="small" @click="checkInvoice">Check validation failed invoices</el-button>
        <el-button size="small" type="primary" @click="onConfirm">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    customer: {
      type: String,
      default: ''
    },
    Batch: {
      type: String,
      default: ''
    },
    reGennerate: {
      type: Object,
      default: () => {}
    },
    messageInvoice: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    visible (newVal) {
      this.childVisible = newVal
    }
  },
  data () {
    return {
      childVisible: false
    }
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    checkInvoice () {
      this.close()
      this.$emit('checkInvoice')
    },
    onConfirm () {
      this.$emit('update:visible', false)
      this.$emit('onComfirm')
    }
  }
}
</script>
<style lang="scss" scoped>
.message-line {
  font-size: 16px;
  margin-bottom: 10px;
}
.message{
  border-top: 1px solid #ccc;
  padding: 10px 0;
  color: rgb(42, 148, 255);
}
.red {
  color: red;
}
.green {
  color: green;
}
.message-basic {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
.title {
  font-weight: bold;
}
.result {
  margin-top: 20px;
}
</style>
