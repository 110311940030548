<template>
  <el-drawer
    :title="config.title"
    :visible.sync="childDrawer"
    @close="onClose"
    :show-close="false"
    :size="332"
    direction="rtl">
    <div class="drawer-container">
      <slot></slot>
    </div>
    <div class="el-drawer-footer">
      <el-button size="small" @click="onCancel" >{{ config.cancelText }}</el-button>
      <el-button type="primary" size="small" @click="onConfirm">{{ config.confirmText }}</el-button>
    </div>
  </el-drawer>
</template>
<script>
export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {
        return {
          title: 'title',
          cancelText: 'Cancel',
          confirmText: 'ConFirm'
        }
      }
    }
  },
  watch: {
    drawer (newVal) {
      this.childDrawer = newVal
    }
  },
  data () {
    return {
      childDrawer: this.drawer
    }
  },
  methods: {
    onConfirm () {
      this.$emit('onConfirm')
    },
    onCancel () {
      this.$emit('onCancel')
    },
    onClose () {
      this.$emit('update:drawer', false)
      this.$emit('onClose')
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
